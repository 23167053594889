let obj = {
  dateFormat: (time, fmt) => {
    return dateFormat(time, fmt);
  },
};

export default {
  install(Vue) {
    Object.keys(obj).forEach((key) => {
      Vue.filter(key, obj[key]);
    });
  },
};
function getMinutesFromOffsetString(offset) {
  const sign = offset[0] === "-" ? -1 : 1;
  const hours = Number(offset.substring(1, 3));
  const minutes = Number(offset.substring(4, 6));
  return sign * (hours * 60 + minutes);
}
// 时间格式化
function dateFormat(time, fmt) {
  if (new Date(time) == "Invalid Date") return time;
  if (!time) return "";
  if (typeof time === "string" && time.includes("T")) {
    time = time.replace("T", " ").replace(/\-/g, "/");
  }

  // 在火狐浏览器中使用自定义的解析函数
  if (navigator.userAgent.includes("Firefox")) {
    const regex =
      /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})\.(\d+)([-+]\d{2}:\d{2})$/;
    let maketime =
      time.split(" ")[0].replaceAll("/", "-") + "T" + time.split(" ")[1];
    const match = maketime.match(regex);
    if (!match) return null;
    const dateString = match[1];
    const milliseconds = Number(match[2]);
    const offset = match[3];
    const date = new Date(dateString);
    date.setMilliseconds(milliseconds);
    // 根据时区偏移调整时间
    const timeZoneOffset = getMinutesFromOffsetString(offset);
    date.setMinutes(date.getMinutes() - timeZoneOffset);
    time = date;
  } else {
    // 在其他浏览器中使用原生的日期解析方法
    time = new Date(time);
  }
  var o = {
    "M+": time.getMonth() + 1,
    "d+": time.getDate(),
    "h+": time.getHours(),
    "m+": time.getMinutes(),
    "s+": time.getSeconds(),
    "q+": Math.floor((time.getMonth() + 3) / 3),
    S: time.getMilliseconds(),
  };

  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (time.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  }
  return fmt;
}
