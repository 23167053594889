import Vue from "vue";
import Router from "vue-router";
import authorization from "@/components/authorization.vue";
import layout from "@/view/index.vue";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/app",
      component: authorization,
      children: [
        {
          path: "userInfo",
          component: layout,
          children: [
            {
              path: "workTable",
              component: (resolve) => require(["@/view/userInfo/workTable.vue"], resolve),
            },
            {
              path: "index",
              meta: {
                title: "个人中心",
              },
              component: (resolve) =>
                require(["@/view/userInfo/index.vue"], resolve),
            },
            {
              path: "workTable",
              meta: {
                title: "工作台",
              },
              component: (resolve) =>
                require(["@/view/userInfo/workTable.vue"], resolve),
            },
            {
              path: "message",
              meta: {
                title: "消息中心",
              },
              component: (resolve) =>
                require(["@/view/messageCenter/index.vue"], resolve),
            },
          ],
        },
        {
          path: "noFrame/:name",
          meta: {
            title: "渔之蓝",
          },
          component: (resolve) =>
            require(["@/components/layout/noFrame.vue"], resolve),
        },
        {
          path: "less-code-web/performance/:type/:code/:id?",
          meta: { title: "智搭" },
          component: (resolve) => require(["@/view/lessCode.vue"], resolve),
        },
        {
          path: "/",
          component: layout,
          children: [
            {
              path: "*",
              meta: { title: "" },
              component: (resolve) => require(["@/components/layout/main.vue"], resolve),
            }
          ]
        },
      ],
    },
    {
      path: "/login",
      meta: {
        title: "登录",
      },
      component: (resolve) => require(["@/view/login.vue"], resolve),
    },
    {
      path: "/:id/login",
      meta: {
        title: "登录",
      },
      component: (resolve) => require(["@/view/login.vue"], resolve),
    },
    {
      path: "/:id/bdhsso",
      meta: {
        title: "授权",
      },
      component: (resolve) => require(["@/view/bdhsso.vue"], resolve),
    },
    {
      path: "*",
      redirect: "/app/userInfo/workTable",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const user = JSON.parse(localStorage.getItem("user"));
  document.title = (to.meta.title ? `${to.meta.title}-` : '') + (user?.sysName || user?.tenantName || "未来校园");
  next();
});

export default router;
