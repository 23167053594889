<template>
  <div class="menu-box" ref="menuBox" v-show="sideMenuList.length > 0">
    <el-menu
      :default-active="activeMenu.menuUrl"
      class="el-menu-vertical-demo"
      :collapse="sideMenuCollapsed"
      :unique-opened="true"
      ref="menu"
    >
      <template v-for="item in sideMenuList">
        <el-submenu
          v-if="item.menuDtos.length"
          v-bind:key="item.id"
          :index="item.menuUrl"
          :popper-append-to-body="false"
        >
          <template slot="title">
          
              <i :class="['iconfont', item.icon, 'icon']"></i>
         
            <el-tooltip
              :disabled="item.name.length <= 7"
              class="item"
              effect="dark"
              :content="item.name"
              placement="top"
              :enterable="false"
            >
              <span>{{ item.name }}</span>
            </el-tooltip>
          </template>
          <!-- TOW -->
          <template v-for="chi in item.menuDtos">
            <template v-if="!chi.menuDtos.length">
              <el-menu-item v-bind:key="chi.id" :index="chi.menuUrl" @click="menuClick(chi)">
                <el-tooltip
                  :disabled="chi.name.length <= 7"
                  effect="dark"
                  :content="chi.name"
                  placement="top"
                  :enterable="false"
                >
                  <span>
                    <span class="item-leaf"> {{ chi.pardName }}</span>
                    <span v-if="chi.chidName" class="child-name">{{
                      chi.chidName
                    }}</span>
                  </span>
                </el-tooltip>
              </el-menu-item>
            </template>
            <template v-else>
              <el-submenu v-bind:key="chi.id" :index="chi.menuUrl" :popper-append-to-body="false">
                <span slot="title"
                  ><el-tooltip
                    :disabled="chi.name.length <= 7"
                    effect="dark"
                    :content="chi.name"
                    placement="top"
                    :enterable="false"
                  >
                    <span>
                      <span class="item-leaf"> {{ chi.pardName }}</span>
                      <span v-if="chi.chidName" class="child-name">{{
                        chi.chidName
                      }}</span></span
                    >
                  </el-tooltip></span
                >
                <!-- THREE -->
                <template>
                  <el-menu-item
                    v-for="leaf in chi.menuDtos"
                    v-bind:key="leaf.id"
                    :index="leaf.menuUrl"
                    @click="menuClick(leaf)"
                    ><el-tooltip
                      :disabled="leaf.name.length <= 7"
                      effect="dark"
                      :content="leaf.name"
                      placement="top"
                      :enterable="false"
                    >
                      <span>
                        <span class="item-leaf"> {{ leaf.pardName }} </span>
                        <span v-if="leaf.chidName" class="child-name">{{
                          leaf.chidName
                        }}</span></span
                      >
                    </el-tooltip></el-menu-item
                  >
                </template>
              </el-submenu>
            </template>
          </template>
        </el-submenu>
        <template v-else>
          <el-menu-item v-bind:key="item.id" :index="item.menuUrl" @click="menuClick(item)">
            <i :class="['iconfont', item.icon, 'icon', 'menu-icon']"></i>
            <el-tooltip
              :disabled="item.name.length <= 7"
              effect="dark"
              placement="top"
              :content="item.name"
              :enterable="false"
            >
              <span>
                <span class="item-leaf">{{ item.pardName }}</span>
                <span v-if="item.chidName" class="child-name">{{
                  item.chidName
                }}</span></span
              >
            </el-tooltip>
          </el-menu-item>
        </template>
      </template>
    </el-menu>

    <div class="is-collapse" @click="clickCollapse">
      <i
        :class="[
          sideMenuCollapsed ? 'icon-toggle-right' : 'icon-toggle-left',
          'icon',
          'iconfont',
        ]"
      ></i>
      <span class="is-collapse-font">收起</span>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { HOME_PATH } from "@/constants";
import { findMenu } from "@/utils";

function formatMenu(menuList) {
  const list = [];
  for (const menu of menuList) {
    if (menu.isShow === 1) {
      const data = { ...menu };
      const names = menu.name.split("-");
      data.chidName = names[1];
      data.pardName = names[0];
      data.menuDtos = formatMenu(menu.menuDtos || []);
      list.push(data);
    }
  }

  return list;
}

export default {
  data() {
    return {
      activeMenu: {}, // 当前选中的叶子菜单
      sideMenuList: [], // 侧边栏菜单列表
    };
  },
  computed: {
    sideMenuCollapsed() {
      return this.$store.state.sideMenuCollapsed;
    },
  },
  watch: {
    "$route.fullPath": {
      immediate: true,
      handler(val) {
        const path = val.replace('/app/', '').replace('#/', '/').split('?')[0];
        if (path !== this.activeMenu.menuUrl) {
          for (const menu of this.$store.state.menuList) {
            const activeMenu = findMenu(menu.menuDtos, path);
            if (activeMenu) {
              this.activeMenu = activeMenu;
              this.sideMenuList = formatMenu(menu.menuDtos || []);
              return;
            }
          }

          this.activeMenu = {};
          this.sideMenuList = [];
        }
      },
    },
  },
  methods: {
    clickCollapse() {
      this.$store.commit("onChange", { key: 'sideMenuCollapsed', value: !this.sideMenuCollapsed });
    },
    menuClick(item) {
      if (/^https?:\/\//.test(item.menuUrl)) {
        window.open(item.menuUrl);
        return;
      }

      this.$router.push(item.menuUrl === HOME_PATH ? HOME_PATH : `/app/${item.menuUrl.replace('/', '#/')}?t=${nanoid()}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-box {
  padding-top: 10px;
  position: relative;
  background-color: #fff;
}
:deep(.is-active) {
  color: var(--primary) !important;
  background: #f3f5fc !important;
}

:deep(.el-menu--inline) {
  .is-active:not(.is-opened) {
    background: #f3f5fc !important;
  }
}

:deep(.el-menu-vertical-demo) {
  height: calc(100vh - 112px) !important;
}
.el-menu {
  border: 0 !important;
}
:deep(.el-submenu.is-active .el-submenu__title) {
  background: #fff !important;
}
:deep(.el-menu:not(.el-menu--inline, .el-menu--collapse)) {
  box-sizing: border-box;
}
:deep(.el-menu-vertical-demo) {
  padding: 0 10px !important;
}
:deep(.el-menu:not(.el-menu--collapse)) {
  border: none !important;
  span {
    color: #585d69 !important;
  }
  .child-name {
    font-size: 12px;
    color: #a4a8b0 !important;
    margin-left: 5px;
  }
  .el-menu--inline {
    .is-active.el-menu-item {
      .item-leaf {
        color: var(--primary) !important;
      }
    }
  }
  i {
    color: #181818 !important;
    font-weight: 400 !important;
  }
  .menu-icon {
  
  }
  i:not(.iconfont) {
    color: #a4a8b0 !important;
    font-size: 15px !important;
    right: 10px !important;
  }
  i:not(.el-icon-arrow-down) {
    color: #a4a8b0 !important;
    margin-right: 6px;
  }
  .el-submenu {
    border-radius: 4px !important;
  }
  .el-submenu__title {
    height: 40px !important;
    line-height: 40px !important;
    .iconfont{
      position: relative;
      top: 2px;
    }
  }
  .el-submenu__title {
    padding-left: 10px !important;
  }
  .el-menu--inline {
    .el-submenu__title {
      padding-left: 50px !important;
    }
  }
  .el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
    padding-left: 50px !important;
    border-radius: 4px;
    min-width: 180px !important;
  }
}
:deep(.el-submenu__title) {
  font-weight: bold;
  border-radius: 4px !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse)::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 400px;
  overflow-y: auto;
}
:deep(.el-menu--vertical) {
  position: fixed !important;
  padding: 0 !important;
}
.icon {
  margin-right: 10px;
  width: 20px;
  text-align: center;
  font-size: 20px;
  vertical-align: middle;
}
.is-collapse {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #e3e2ec;
  z-index: 999 !important;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  background: #fff;
  z-index: 9;
  i {
    margin-right: 0 !important;
    border-radius: 50%;
    font-weight: 200;
    font-size: 19px;
    color: #a4a8b0;
    width: 64px;
    height: 49px;
    overflow: hidden;
    display: inline-block;
    line-height: 49px;
  }
  .is-collapse-font {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
  }
}
:deep(.el-icon-arrow-down::before) {
  content: "\e790" !important;
}
:deep(.el-menu-item:hover) {
  background: #f3f5fc !important;
}
:deep(.el-menu--inline) {
  .el-submenu {
    .el-menu--inline {
      .el-menu-item {
        padding-left: 65px !important;
      }
    }
  }
}

:deep(.el-menu--collapse.el-menu) {
  padding: 0 !important;
  i {
    color: #a4a8b0 !important;
    font-weight: 400 !important;
  }
  .el-submenu__title,
  .el-menu-item {
    height: 40px !important;
    line-height: 38px !important;
  }

  .el-menu-item {
    padding: 0 20px !important;
  }
  .el-submenu__title {
    font-weight: 400 !important;
  }
  .el-icon-arrow-right::before {
    content: "\e790";
  }
  .is-active {
    .el-submenu__title {
      background: #f3f5fc !important;
    }
  }
  .el-submenu__title:hover {
    background: #f3f5fc !important;
  }
}

:deep(.el-menu-item, .el-submenu__title) {
  .el-tooltip {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 125px !important;
  }
}
:deep(.el-menu-vertical-demo:not(.el-menu--collapse)) > .el-menu-item {
  padding: 0 20px 0 10px !important;
  font-weight: bold;
}
.el-menu--collapse + .is-collapse {
  .is-collapse-font {
    display: none;
  }
}
.is-collapse {
  overflow: hidden;
}
.is-collapse-font {
  font-size: 14px;
  color: #585d69;
  margin-bottom: 1px;
  margin-left: -15px;
}
:deep(.el-submenu__title:hover) {
  background: #f3f5fc !important;
}

:deep(.is-active:not(.is-opened)) {
  background: #f3f5fc !important;
  span {
    color: var(--primary) !important;
  }
  .el-submenu__title {
    span {
      color: #585d69 !important;
    }
  }
}
</style>
