<template>
  <div class="index-main">
    <headers></headers>
    <div class="center">
      <menus ref="menus"></menus>
      <div class="content-box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import header from "@/components/layout/header.vue";
import menu from "@/components/layout/menu.vue";

export default {
  components: {
    menus: menu,
    headers: header,
  },
};
</script>
<style lang="scss" scoped>
.index-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  min-width: 1360px;
}
.center {
  flex: 1;
  display: flex;
}
.content-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
