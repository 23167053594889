<template>
  <div class="tooltip-wrap">
    <el-tooltip
      ref="tlp"
      effect="dark"
      :disabled="!tooltipFlag"
      :placement="placement"
      class="tooltip"
      popper-class="tool_tip"
    >
      <!-- 此位置主要设置提示框的宽度 -->
      <template slot="content">
        <div
          :style="{
            width: !isParentWidth
              ? toolTipWidth
                ? `${toolTipWidth}`
                : '100%'
              : toolTipParentWidth
              ? `${toolTipParentWidth}px`
              : '100%',
            boxSizing: 'border-box',
            color: color,
          }"
        >
          {{ text }}
        </div>
      </template>
      <span
        :class="className"
        :style="{
          maxWidth: width ? `${width}px` : `${boxWidth}px`,
          minWidth: minWidth ? `${minWidth}px` : '',
        }"
        @mouseenter="visibilityChange($event)"
        >{{ text }}</span
      >
    </el-tooltip>
  </div>
</template>
 
<script>
export default {
  name: "toolTip",
  props: {
    text: {
      // 文本内容
      type: [String, Number],
      default: () => "",
    },
    className: {
      type: String,
      default: () => "tool-tip-text",
    },
    placement: {
      type: String,
      default: () => "top-start",
    },
    // 限制文本显示省略号的宽度
    width: {
      type: Number,
      default: () => 0,
    },
    minWidth: {
      type: Number,
      default: () => 0,
    },
    // 限制文本显示省略号的宽度
    toolTipWidth: {
      type: String,
      default: () => "",
    },
    fontSize: {
      type: Number,
      default: () => 14,
    },
    // 提示框是否与外层父元素一样宽
    isParentWidth: Boolean,
    color: {
      type: String,
      default: () => "#fff",
    },
  },
  computed: {},
  data() {
    return {
      disabledTip: false,
      tooltipFlag: false,
      boxWidth: 0,
      toolTipParentWidth: 0,
    };
  },
  mounted() {
    this.visibilityChange({ target: { outerText: this.text } });
  },
  updated(){
    this.visibilityChange({ target: { outerText: this.text } });
  },
  methods: {
    visibilityChange(e) {
      this.$nextTick(() => {
        const ev = e.target;
        let html = ev.outerText;
        let span = document.createElement("span");
        span.style.fontSize = `${this.fontSize}px`;
        span.innerHTML = html;
        span.style.position = "absolute";
        document.body.appendChild(span);
        let sWidth = span.offsetWidth;
        document.body.removeChild(span);
        let boxWidth = this.$el.offsetWidth;
        if (this.isParentWidth && !this.width && !this.minWidth) {
          this.toolTipParentWidth = boxWidth - 20;
        }
        if (this.width) {
          this.tooltipFlag = this.width < sWidth;
        } else if (this.minWidth) {
          this.tooltipFlag = this.minWidth < sWidth;
        } else {
          this.tooltipFlag = boxWidth < sWidth;
          this.boxWidth = boxWidth;
        }
      });
    },
    updateToolTip() {
      this.visibilityChange({ target: { outerText: this.text } });
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltip-wrap {
  display: flex;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tooltip {
    width: 100%;
  }
}
</style>