require("promise.prototype.finally").shim();
import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import microApp from "@micro-zoe/micro-app";
import ElementUI from "element-ui";
import { VueCropper } from "vue-cropper";
import axios from "@/http/axios.js";
import directives from "@/common/js/directives.js";
import filters from "@/common/js/filters.js";
import toolTip from "@/components/toolTip/toolTip.vue";
import verIfy from "yzl-verify";
import theme from "@/common/js/theme";

Vue.component("verIfy", verIfy);
Vue.use(toolTip);
Vue.use(directives);
Vue.use(filters);
Vue.prototype.$microApp = microApp;
Vue.prototype.$http = axios;
Vue.prototype.$theme = theme;

microApp.start({
  'disable-memory-router': true, // 关闭虚拟路由系统
  'disable-patch-request': true, // 关闭对子应用请求的拦截
});

Vue.use(ElementUI);
Vue.use(VueCropper);
Vue.config.productionTip = false;
const user = JSON.parse(localStorage.getItem("user") || '{}');
microApp.setGlobalData({
  theme: user?.themeColor || localStorage.getItem("--primary"),
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
