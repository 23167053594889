
export function findMenu(menuList, path) {
  for (const menu of menuList) {
    if (menu.menuUrl === path) {
      return menu;
    }

    if (menu.menuDtos && menu.menuDtos.length) {
      const found = findMenu(menu.menuDtos, path);
      if (found) return found;
    }

    if (menu.secondaryMenuDtos && menu.secondaryMenuDtos.length) {
      const found = findMenu(menu.secondaryMenuDtos, path);
      if (found) return menu;
    }
  }
}
