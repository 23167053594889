import axios from "axios";

import utils from "@/common/js/index";

const service = axios.create({
  baseURL: process.env.VUE_APP_AIP,
  timeout: 5000 * 10,
});

service.interceptors.request.use(
  (config) => {
    return utils.utils.axiosHeader(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return utils.utils.axiosResponse(response);
  },
  (Error) => {
    return utils.utils.axiosError(Error);
  }
);

export default service;
