import Theme from "theme-vue";
import microApp from "@micro-zoe/micro-app";

let Th = new Theme();

/**
 * 修改主题色
 * @param {string} theme 主题色，16进制颜色值
 */
function changeTheme(theme) {
  microApp.setGlobalData({ theme });
  Th.changeTheme(theme);
}

export default { changeTheme };
