import Vue from "vue";
import Vuex from "vuex";
import { nanoid } from "nanoid";
Vue.use(Vuex);

const USER_STYLE_ID = `user-style-${nanoid()}`;

const getDefaultState = () => {
  return {
    userData: {},
    menuList: [],
    sideMenuCollapsed: !!localStorage.getItem("SIDE_MENU_COLLAPSED"),
    campusList: [],
    is_Message: false,
  };
}

const store = new Vuex.Store({
  state() {
    return getDefaultState();
  },
  getters: {
    is_Message:(state)=>{
      return state.is_Message;
    },
  },
  mutations: {
    onChange(state, data) {
      state[data.key] = data.value;
    },
    reset(state) {
      localStorage.clear();
      sessionStorage.clear();
      Object.assign(state, getDefaultState());
    },
    // 更新用户信息
    updateUserData(state, newData) {
      // 更新主题色
      if (state.userData.themeColor !== newData.themeColor) {
        const newThemeColor = newData.themeColor || "#009df5";
        localStorage.setItem("--primary", newThemeColor);
        Vue.prototype.$theme.changeTheme(newThemeColor)
      }

      // 添加用户自定义样式
      if (state.userData.style !== newData.style) {
        document.head.querySelector(`#${USER_STYLE_ID}`)?.remove();
        const style = document.createElement("style");
        style.id = USER_STYLE_ID;
        style.textContent = newData.style;
        document.head.appendChild(style);
      }

      state.userData = newData || {};
      if (!newData || Object.keys(newData).length === 0) {
        localStorage.removeItem("user");
      } else {
        localStorage.setItem("user", JSON.stringify(newData));
      }
    },
    menuListChange(state, data) {
      state.menuList = data;
      sessionStorage.setItem("menuList", JSON.stringify(data));
    },
  },
});

export default store;
