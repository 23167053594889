<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import qs from 'qs';
import util from "@/common/js/index";
export default {
  mounted() {
    const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    // 重定向只支持根路径
    if (location.pathname === '/' && urlParams.redirecturl) {
      location.href = urlParams.redirecturl;
    }

    if (sessionStorage.getItem("changemakeTai")) {
      this.$router.push("/app/userInfo/workTable");
    }

    for (const item of util.utils.iconfontUrl) {
      let iconLink = document.createElement("link");
      iconLink.rel = "stylesheet";
      iconLink.href = item;
      document.head.appendChild(iconLink);
    }
    this.setGlobalStyle();
    setInterval(() => {
      this.refreshToken();
    }, 1000 * 60);

    //设置窗口图标ico
    if (sessionStorage.getItem("tenantData")) {
      const tenantInfo = JSON.parse(sessionStorage.getItem("tenantData"));
      var link =
        document.querySelector('link[rel*="icon"]') ||
        document.createElement("link");
      link.rel = "icon";
      link.href = tenantInfo.htmlIcoImage || "";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  },
  methods: {
    setGlobalStyle() {
      const root = document.documentElement;
      root.style.setProperty("--border-color", "#ebeef5");
      root.style.setProperty("--shallow-color", "#a4a8b0");
      root.style.setProperty("--delete-color", "#ed3f14");
      root.style.setProperty("--tree-background-color", "#f3f5fc");
    },
    refreshToken() {
      const token = JSON.parse(localStorage.getItem("token"));
      // 每隔一分钟去判断token过期时间是否小于当前时间5分钟
      if (token && token.expTime - Date.now() < 1000 * 60 * 5) {
        this.$http({
          url: "/v1/identity-service/api/account/refreshToken",
          method: "post",
          data: {
            refreshToken: token.refreshToken,
          },
        })
          .then((res) => {
            localStorage.setItem("token", JSON.stringify({ ...res, expTime: res.expireInSeconds * 1000 + Date.now() }));
          })
          .catch(() => {
            this.logout();
          });
      }
    },
    logout() {
      this.$http({
        url: "/v1/identity-service/api/account/logout",
        method: "post",
      }).then(() => {
        const tenantCode = this.$store.state.userData.tenantCode;
        this.$store.commit("reset");
        localStorage.clear();
        sessionStorage.clear();
        if (tenantCode) {
          window.location.replace(`/${tenantCode}/login`);
        } else {
          window.location.replace("/login");
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/common/css/index.scss";
@import "@/common/css/public.scss";
#app {
  height: 100%;
}
.content {
  width: 100%;
  overflow-y: auto;
  flex: 1;
  background: #f0f2f5;
  .center {
    height: 100%;
    position: relative;
    background: #f5f7f9;
    overflow: hidden;
    overflow-y: auto;
  }
}
a {
  text-decoration: none;
  color: var(--primary);
}
</style>
