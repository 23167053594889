let obj = {
  btn: {
    bind(el, binding, VNode) {
      const that = VNode.context;
      if (!that.$route.meta[binding.value]) {
        el.style.display = "none";
      }
    },
  },
  clickOutside: {
    bind(el, binding, vnode) {
      function documentHandler(e) {
        if (el.contains(e.target)) {
          return false;
        }
        if (binding.expression) {
          if (e?.target?._prevClass?.indexOf("el-icon-circle-close") > -1)
            return; // 解决避免点击input框内清除icon的时候关闭
          binding.value(e);
        }
      }
      el.__vueClickOutside__ = documentHandler;
      document.addEventListener("click", documentHandler);
    },
    update() {},
    unbind(el, binding) {
      document.removeEventListener("click", el.__vueClickOutside__);
      delete el.__vueClickOutside__;
    },
  },
  //按tab键光标不跑到dialog框外
  formTab: {
    bind: function (el, binding) {
      let bidF = typeof binding.value?.flag === "boolean";
      el.addEventListener("keydown", function (event) {
        if (event.key === "Tab") {
          var formItems = el.querySelectorAll(".el-form-item");
          var tooltip = el.querySelectorAll(".el-tooltip");
          Array.from(tooltip).map((v) => {
            v.tabIndex = -1;
          });

          var focusableElements = Array.from(formItems).flatMap(function (
            formItem
          ) {
            return Array.from(
              formItem.querySelectorAll("input, select, textarea, a[href]")
            );
          });
          focusableElements.map((v) => {
            if (v._prevClass == "el-cascader__search-input") {
              v.tabIndex = -1;
            }
          });
          focusableElements = focusableElements.filter((v, index) =>
            !bidF
              ? (!v.disabled && v._prevClass == "el-input__inner") ||
                v._prevClass == "el-textarea__inner"
              : el.flag
              ? !v.disabled && v._prevClass == "el-input__inner"
              : index < 3
          );
          var firstElement = focusableElements[0];
          var lastElement = focusableElements[focusableElements.length - 1];
          var activeElement = document.activeElement;
          if (!event.shiftKey && activeElement === lastElement) {
            // 按下 Tab 键且焦点在最后一个元素时，将焦点切换到第一个元素
            event.preventDefault();
            firstElement.focus();
          } else if (event.shiftKey && activeElement === firstElement) {
            // 按下 Shift + Tab 键且焦点在第一个元素时，将焦点切换到最后一个元素
            event.preventDefault();
            lastElement.focus();
          }
        }
      });
    },
    update: function (el, binding) {
      if (typeof binding.value?.flag === "boolean") {
        el.flag = binding.value.flag;
      }
    },
  },
//点击保存按钮光标固定到未填必填项里
  focusfirstEmpty: {
    bind: function (el, binding, vnode) {
      el.addEventListener("click", function (event) {
        const form = vnode.context.$refs[el.dataset.formRef];
        if (!form) {
          return;
        }
        event.preventDefault();
        let firstEmptyElement = null;
        const formItems = form.$el.querySelectorAll(".el-form-item");
        for (const item of formItems) {
          const inputs = item.querySelectorAll("input, select, textarea");
          for (const input of inputs) {
            if (item.classList.contains("is-required") && !input.value) {
              firstEmptyElement = input;
              break;
            }
          }
          if (firstEmptyElement) {
            break;
          }
        }
        if (firstEmptyElement) {
          firstEmptyElement.focus();
        }
      });
    },
  },
};
export default {
  install(Vue) {
    Object.keys(obj).forEach((key) => {
      Vue.directive(key, obj[key]);
    });
  },
};
