import { Message } from "element-ui";
import directives from "./directives";
import filters from "./filters";
import axios from "@/http/axios.js";
import { forIn } from "lodash-es";
import store from "@/store";
export default {
  utils: {
    iconfontUrl: [
      "https://static.yzl.ltd/common/saas-edu/iconfont/platform/iconfont.css",
    ],
    // 获取guid
    getGuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    // 文件下载
    download(url, showName, callBack) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            callBack();
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          callBack();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = showName;
          a.click();
        })
        .catch((error) => {
          callBack();
          console.error("There was a problem with the fetch operation:", error);
        });
    },
    // 设置请求头
    axiosHeader: (config) => {
      // Start 删除请求参数字段为：空字符串、undefined、null
      forIn(config.params, (value, key) => {
        if (value === "" || value === undefined || value === null)
          delete config.params[key];
      });
      // End
      if (config.method === "get") {
        config.data = true;
      }
      let token = JSON.parse(localStorage.getItem("token"));
      config.headers["Authorization"] =
        "Bearer " +
        (token && token.encryptedAccessToken ? token.encryptedAccessToken : "");
      const currentCampus =
        (sessionStorage.getItem("currentCampus") &&
          JSON.parse(sessionStorage.getItem("currentCampus"))) ||
        {};
      config.headers["campus"] = currentCampus.id || "";
      return config;
    },
    // 请求响应
    axiosResponse: async (response) => {
      if (response.status > 300) {
        Message({
          message: response.data.error.message || "Error",
          type: "error",
          duration: 3 * 1000,
        });
      } else {
        return response.data;
      }
    },
    // 请求拦截
    axiosError: async (Error, win) => {
      if (Error.request.status == 401) {
        let tenantCode = JSON.parse(localStorage.getItem("user"))?.tenantCode;
        localStorage.clear();
        sessionStorage.clear();
        // 子应用在基座
        if (win?.__MICRO_APP_ENVIRONMENT__) {
          win.microApp?.dispatch({ is401: true, tenantCode: tenantCode });
          return;
        } else if (window.$isChiModule) {
          // 子应用不在基座
          Message({
            message: "token失效",
            type: "error",
            duration: 3 * 1000,
          });
          return;
        } else {
          // 基座
          if (tenantCode) {
            window.location.replace(`/${tenantCode}/login`);
          } else {
            window.location.replace("/login");
          }
        }
        return Promise.reject(Error.response);
      }
      if (Error.request.status == 403) {
        Message({
          message: Error.response.data.error?.message || "无接口权限",
          type: "error",
          duration: 3 * 1000,
        });
        return Promise.reject(Error.response);
      }

      Message({
        message: Error.response.data.error?.message || "发生未知错误",
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(Error.response);
    },
    // 子模块请求权限
    async getAuthority(to, path) {
      let list = this.inUrlGetId(path, "sub");
      if (!list || !list.length) {
        return;
      }
      await axios
        .get(
          `/v1/saas-system-service/api/menu-action/${
            list[list.length - 1].id
          }/action`
        )
        .then((res) => {
          for (const item of res) {
            to.meta[item.code] = true;
          }
          return;
        });
    },
    clearKeepAlive(to) {
      let vnode = to?.matched[1]?.instances?.default?.$vnode?.parent;
      let parentVnode = vnode && vnode.parent;
      if (
        parentVnode &&
        parentVnode.componentInstance &&
        parentVnode.componentInstance.cache
      ) {
        let key =
          vnode.key == null
            ? vnode.componentOptions.Ctor.cid +
              (vnode.componentOptions.tag
                ? `::${vnode.componentOptions.tag}`
                : "")
            : vnode.key;
        let cache = parentVnode.componentInstance.cache;
        let keys = parentVnode.componentInstance.keys;
        if (cache[key]) {
          if (keys.length) {
            let index = keys.indexOf(key);
            if (index > -1) {
              keys.splice(index, 1);
            }
          }
          cache[key] = null;
        }
      }
    },
    getNextUrl(url) {
      let currentList = JSON.parse(sessionStorage.getItem("currentList"));
      let currentId = sessionStorage.getItem("currentId");
      let obj = currentList.find((v) => v.id == currentId);
      if (obj.secondaryMenuDtos) {
        let chi = obj.secondaryMenuDtos.find(
          (v) => v.menuUrl.indexOf(url) > -1
        );
        if (chi) {
          currentList.push(chi);
          sessionStorage.setItem("currentList", JSON.stringify(currentList));
        }
        return url;
      } else {
        console.error("菜单中未添加该二级菜单！");
      }
    },
    // 根据菜单链接获取菜单id
    inUrlGetId(url, type = "main") {
      url = (url || "").split("?")[0];
      let list;
      if (type === "main") {
        list = store.state.menuList;
      } else {
        list = JSON.parse(sessionStorage.getItem("menuList"));
      }

      return fn(list);
      function fn(menuItems) {
        for (let menuItem of menuItems) {
          if (menuItem.menuUrl === url) {
            return [menuItem];
          } else if (menuItem.menuDtos && menuItem.menuDtos.length > 0) {
            let found = fn(menuItem.menuDtos, url);
            if (found) {
              return [menuItem, ...found];
            }
          } else if (
            menuItem.secondaryMenuDtos &&
            menuItem.secondaryMenuDtos.length > 0
          ) {
            let found = fn(menuItem.secondaryMenuDtos, url);
            if (found) {
              return [menuItem, ...found];
            }
          }
        }
        return null;
      }
    },
    // 隐藏所有tooltip，解决：路由跳转时，部分tooltip不消失
    hideAllTooltips() {
      const nodeList = document.querySelectorAll(".el-tooltip__popper");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].style.display = "none";
      }
    },
    // 隐藏所有异常Dialog，解决：因为模块切换的时候部分Dialog异常
    hideAllDialog() {
      const dialogList = document.querySelectorAll(".v-modal");
      const wrapperList = document.querySelectorAll(".el-dialog__wrapper");
      for (let i = 0; i < wrapperList.length; i++) {
        wrapperList[i].style.display = "none";
      }
      for (let i = 0; i < dialogList.length; i++) {
        dialogList[i].style.display = "none";
      }
    },
    // 切换模块，隐藏所有的el-message
    hideAllMessage() {
      const messageList = document.querySelectorAll(".el-message");
      for (let i = 0; i < messageList.length; i++) {
        messageList[i].remove();
      }
    },
    // 基座向子模块发送消息时并注入脚本(避免后期维护子模块)
    injection(type) {},
  },
  directives,
  filters,
};
