var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tooltip-wrap"},[_c('el-tooltip',{ref:"tlp",staticClass:"tooltip",attrs:{"effect":"dark","disabled":!_vm.tooltipFlag,"placement":_vm.placement,"popper-class":"tool_tip"}},[_c('template',{slot:"content"},[_c('div',{style:({
          width: !_vm.isParentWidth
            ? _vm.toolTipWidth
              ? `${_vm.toolTipWidth}`
              : '100%'
            : _vm.toolTipParentWidth
            ? `${_vm.toolTipParentWidth}px`
            : '100%',
          boxSizing: 'border-box',
          color: _vm.color,
        })},[_vm._v(" "+_vm._s(_vm.text)+" ")])]),_c('span',{class:_vm.className,style:({
        maxWidth: _vm.width ? `${_vm.width}px` : `${_vm.boxWidth}px`,
        minWidth: _vm.minWidth ? `${_vm.minWidth}px` : '',
      }),on:{"mouseenter":function($event){return _vm.visibilityChange($event)}}},[_vm._v(_vm._s(_vm.text))])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }